<template>
    <div class="BigBox">
      <!-- 头部 -->
      <div class="TouBu">
          <!-- 按钮 -->
          <img @click="GoHome" src="../../assets/ConstructionLandscape/Home.png" alt=""
              style="height:70%;cursor: pointer;margin-right: 50vh;">
          <!-- 标题 -->
          <div class="NavTitle">
              <div>历史脉络</div>
          </div>
          <!-- 按钮 -->
          <img @click="GoLogin" src="../../assets/ConstructionLandscape/GeRen.png" alt=""
              style="height: 70%;cursor: pointer;margin-left: 50vh;">
      </div>
      <!-- 面包屑 -->
      <div style="width: 90%;margin: 0 auto;font-size: 1.8vh;cursor: pointer;">当前位置：红色文化馆 》红色人物 》红色人物详情</div>
      <!-- 内容 -->
      <div class="ConBox">
          <div style="width: 50%;height: 100%;border: 1px solid red;display: flex;flex-direction: column-reverse;background-size: 100% 100%;"
          :style="{ 'backgroundImage': 'url(' + HistoricalContextDetail.logoUrl + ')' }">
                <div style="width: 100%;padding: 1.5vh 0;background-color: rgba(183,183,183,0.7);display: flex;justify-content: center;">
                    图集 (点击查看更多)
                </div>
          </div>
          <div style="width: 45%;height: 100%; border: 1px solid red;line-height:4vh;overflow-y: scroll;">
                <div style="font-size: 3.5vh;">{{ HistoricalContextDetail.title }}·{{HistoricalContextDetail.dieDate}}</div>
                <div style="font-size: 3.5vh;">简介：</div>
                <div style="margin: 1vh 0;text-indent: 2em;">{{HistoricalContextDetail.summary}}</div>
          </div>
      </div>
      <div class="ConTitle">{{HistoricalContextDetail.title}}</div>
      <div class="ConTitle" style="display: flex;color: rgba(199, 199, 199, 1);align-items: center;">
          <div>发布来源：平台发布</div>
          <div style="width: 1vh;height: 1vh;background-color: rgba(199, 199, 199, 1);border-radius: 50%;margin: 0 1vh;"></div>
          <div>发布作者：平台运营人员</div>
          <div style="width: 1vh;height: 1vh;background-color: rgba(199, 199, 199, 1);border-radius: 50%;margin: 0 1vh;"></div>
          <div>发布时间：{{HistoricalContextDetail.createTime}}</div>
          <div style="width: 1vh;height: 1vh;background-color: rgba(199, 199, 199, 1);border-radius: 50%;margin: 0 1vh;"></div>
          <div>所属古镇：{{HistoricalContextDetail.scenicName}}</div>
      </div>
      <div class="ConTitle" style="display: flex;color: rgba(199, 199, 199, 1);align-items: center;justify-content: space-between;height: 19%;border: 1px solid red;width: 77%;position: relative;left: -5%;">
          <img style="width: 1%;" src="../../assets/VRVisit/VideoLog/Left.png" alt="">
          <div style="width: 95%;border: 1px solid red;height: 100%;display: flex;overflow: auto;justify-content: space-around;">
              <div v-for="(ModoelNavBoxList, index) in 7 " :key="index"
                  style="width: 200px;height: 100%;border: 1px solid blue;flex-shrink: 0;">
  
              </div>
          </div>
          <img style="width: 1%;" src="../../assets/VRVisit/VideoLog/Right.png" alt="">
      </div>
    </div>
  </template>
  
  <script>
  export default {
      data() {
          return{
              // 历史脉络详情数据
              HistoricalContextDetail:''
          }
      },
      components:{
          
      },
      mounted() {
        this.GetHistoricalContextDetail(this.$route.query.GoRedFigureDetails)
      },
      methods: {
          // 返回首页
          GoHome(){
              this.$router.push('/');
          },
          GetHistoricalContextDetail(personageId){
              var axios = require('axios');
              var config = {
                  method: 'get',
                  url: this.$Schttp + '/vtp/app/personage/detail/'+personageId,
                  headers: {
                      Authorization: "Bearer " + this.$cookies.get("token"),
                      "Content-Type": "application/json",
                  },
  
              };
              axios(config).then((res) => {
                  this.HistoricalContextDetail = res.data.data
              }).catch(function (error) {});
          }
      }
  }
  </script>
  
  <style lang="less" scoped>
  .BigBox{
      width: 100%;
      height: 100%;
      background-image: url(../../assets/VRVisit/VideoLog/moreBG.png);
      background-size: 100% 100%;
      min-width: 1440px;
      color: white;
      .ConBox{
          width: 80%;
          margin: 2vh auto;
          height: 50%;
          border: 1px solid red;
          display: flex;
          justify-content: space-between;
      }
      .ConTitle{
          width: 80%;
          margin: 1vh auto;
      }
      .TouBu {
          width: 100%;
          height: 14%;
          display: flex;
          justify-content: space-around;
          align-items: center;
          .NavTitle {
              margin-top: -6vh;
              font-size: 4vh;
              font-weight: 1000;
              margin-right: 4vh;
              color: rgb(125,151,241);
          }
      }
      ::-webkit-scrollbar {
          display: none;
          scrollbar-width: none;
          -ms-overflow-style: none;
      }
  
  }
  </style>